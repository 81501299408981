<template>
  <div>
    <div
      class="collapse navbar-collapse-left slidepanelToogle"
      :class="showToogle"
      id="slidepanel"
    >
      <div class="fix-orderdetails h-100">
        <button
          class="navbar-toggler navbar-toggler-right close-btn border-0 orderpanel-close-btn"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#slidepanel"
          aria-controls="slidepanel"
          aria-expanded="true"
          aria-label="Toggle navigation"
        >
          <span><i class="fas fa-times"></i></span>
        </button>
        <div
          class="order-details h-100 d-flex justify-content-between flex-column"
        >
          <div class="order-top">
            <div class="order-details-heading" v-if="cart.length > '0'">
              <h4>
                {{ $t("Your Order") }}
                <span class="total-items"
                  >({{ cart.reduce((a, c) => a + c.quantity, 0) }}
                  {{
                    cart.reduce((a, c) => a + c.quantity, 0) > 1
                      ? $t("Items")
                      : $t("Item")
                  }})</span
                >
              </h4>
              <div class="clen-crt-btn">
                <p class="crt-clnt-txt mb-0" @click="cleanCart()">
                  {{ $t("Clear Cart") }}
                </p>
              </div>
            </div>
            <div
              class="order-items-main orderitems-nw"
              v-if="cart.length > '0'"
            >
              <div
                class="order-item mt-3"
                v-for="(item, index) in cart"
                :key="index"
              >
                <div class="d-flex align-items-start justify-content-between">
                  <div class="item-heading d-inline-flex align-items-center">
                    <div class="item-nme | d-inline-flex">
                      <div class="vngic veg" v-if="item.item_type == '0'">
                        <span></span>
                      </div>
                      <div class="non-veg vngic" v-if="item.item_type == '1'">
                        <span></span>
                      </div>
                      <div>
                        <p class="mb-0">{{ item.name }} :</p>
                        <div class="d-flex justify-content-beetween">
                          <div class="cart-price-div">
                            <span
                              v-html="$auth.setting && $auth.setting.currency"
                              class="currency"
                            ></span
                            >{{ getTotalItemPrice(item).toFixed(2) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-center">
                    <div class="d-flex align-items-center">
                      <div class="number d-flex align-items-center">
                        <span
                          class="minus d-flex justify-content-center align-items-center"
                          @click="minusItem(item, index)"
                          >-</span
                        >
                        <input type="text" :value="item.quantity" readonly />
                        <span
                          class="plus d-flex justify-content-center align-items-center"
                          @click="plusItem(item, index)"
                          >+</span
                        >
                      </div>
                      <span class="remove_item" @click="deleteProduct(index)"
                        ><i class="fa fa-trash" aria-hidden="true"></i
                      ></span>
                    </div>
                    <div
                      v-if="item.selected_type.length > 0"
                      class="customized"
                    >
                      <p class="customized-lable text-center">customized</p>
                      <div class="selected_type flex-column">
                        <div
                          class="selected-main"
                          v-for="(itemOption, key) in item.selected_type"
                          :key="key"
                        >
                          <p class="selected-radio">{{ itemOption }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="order-items-main cart-emt-cls">
              <img src="@/assets/images/empty_cart.png" />
              <p class="empty-cart mt-4">
                {{ $t("Your cart is empty Add items to get started") }}
              </p>
            </div>
          </div>
          <div
            class="checkout-btn full-btn border-top"
            v-if="
              cart.length > '0' &&
              $auth.setting &&
              $auth.setting.is_system_available == '1'
            "
          >
            <!-- <p>{{ $t("Minimum Order Value")}}<span v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{ restaurantDetailsBySlug.minimum_order_value }}</p> -->
            <b-button
              variant="primary"
              class="nxt-btn"
              disabled
              v-if="is_btn_spinner"
            >
              <b-spinner small type="grow"></b-spinner>
              {{ $t("Loading") }}...
            </b-button>
            <a
              v-else-if="
                Number(miniumOrderValue) > Number(getTotalAmount().toFixed(2))
              "
              disabled
              href="javascript:void(0)"
              class="disable-cart"
              >{{ cart.reduce((a, c) => a + c.quantity, 0) }}
              {{
                cart.reduce((a, c) => a + c.quantity, 0) > 1
                  ? $t("Items")
                  : $t("Item")
              }}
              |
              <span
                v-html="$auth.setting && $auth.setting.currency"
                class="currency"
              ></span
              >{{ getTotalAmount().toFixed(2) }} {{ $t("View Cart") }}</a
            >
            <a v-else href="javascript:void(0)" @click="minimunOrderValue()"
              >{{ cart.reduce((a, c) => a + c.quantity, 0) }}
              {{
                cart.reduce((a, c) => a + c.quantity, 0) > 1
                  ? $t("Items")
                  : $t("Item")
              }}
              |
              <span
                v-html="$auth.setting && $auth.setting.currency"
                class="currency"
              ></span
              >{{ getTotalAmount().toFixed(2) }} {{ $t("View Cart") }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <!--Alert Modal start-->
    <AlertModal
      v-if="isAlert"
      :DisplayAlertData="AlertData"
      v-on:modalClose="onModalCloseFun"
    ></AlertModal>
    <!--Alert Modal End-->
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import AlertModal from "../components/AlertModal.vue";

export default {
  name: "cart",
  components: { AlertModal },
  data() {
    return {
      isAuthenticated: false,
      restaurantData: {},
      restMinimumOrder: 0,
      isAlert: false,
      AlertData: {},
      is_btn_spinner: false,
      showToogle: "show",
    };
  },

  beforeCreate() {
    let localCart = localStorage.getItem("cart");
    if (localCart && localCart != "" && localCart != null) {
      this.$store.commit("product/setCart", JSON.parse(localCart));
    }
  },

  mounted() {
    this.getTotalAmount();
    if (this.$auth.user) {
      this.isAuthenticated = true;
    } else {
      this.isAuthenticated = false;
    }
  },
  computed: {
    ...mapGetters("product", ["cart"]),
    ...mapGetters("restaurant", [
      "restaurantDetailsBySlug",
      "restaurantDetails",
    ]),
    miniumOrderValue() {
      var value = 0;
      if (
        parseInt(this.restaurantDetailsBySlug.minimum_order_value) <=
        parseInt(this.$auth.setting.min_order_value)
      ) {
        value = this.$auth.setting.min_order_value;
      } else {
        value = this.restaurantDetailsBySlug.minimum_order_value;
      }
      return value;
    },
  },
  methods: {
    ...mapActions("product", ["addProductToCart", "removeProductFromCart"]),
    ...mapActions("restaurant", ["getRestaurantDetails"]),
    ...mapMutations("product", [
      "decrementProductInventary",
      "IncrementItemQuantity",
      "setCart",
    ]),

    cartOpenModal() {
      const element = document.querySelector(".slidepanelToogle");
      if (element.classList.contains("show")) {
        this.showToogle = "hide";
      } else {
        this.showToogle = "show";
      }
    },

    onModalCloseFun(value) {
      this.isAlert = false;
    },

    //Increament Item
    plusItem(item, index) {
      //const cartItemIndex = this.cart.findIndex(itm => parseInt(itm.item_id) === parseInt(item.item_id));
      let cartItem = {
        cartItemIndex: index,
        product: item,
      };
      if (item.itemTotalQuantity > item.quantity) {
        this.$store.commit("product/IncrementItemQuantity", cartItem);
      } else {
        alert(this.$t("Maximum items reached"));
      }
    },

    //Decrement Item
    minusItem(cartProduct, index) {
      if (cartProduct.quantity == "1" || cartProduct.quantity == "0") {
        this.deleteProduct(index);
      } else {
        this.$store.commit("product/decrementProductInventary", index);
      }
    },

    //Remove Item from cart
    deleteProduct: function (index) {
      if (this.cart.length == 1) {
        localStorage.setItem("orderUploadImages", "");
      }
      this.$store.dispatch("product/removeProductFromCart", index);
    },

    //Display Total Amount Of item cart
    getTotalItemPrice(item) {
      return item.price * item.quantity;
    },

    //Display Total Amount Of Cart
    getTotalAmount() {
      let total = 0;
      this.cart.forEach((item) => {
        total += item.price * item.quantity;
      });
      return total;
    },

    minimunOrderValue() {
      this.is_btn_spinner = true;
      if (!this.cart.length) {
        alert(this.$t("Please add Item in to cart"));
        this.is_btn_spinner = false;
        return false;
      }
      if (this.$auth.user) {
        if (this.restaurantDetailsBySlug) {
          this.restMinimumOrder =
            this.restaurantDetailsBySlug.minimum_order_value;
          this.alertModal(this.restMinimumOrder);
        } else {
          this.getRestaurantDetailsFun();
        }
      } else {
        this.is_btn_spinner = false;
        this.$router.push({ name: "sign-in" });
      }
    },

    getRestaurantDetailsFun() {
      var restaurantId = this.cart[0].restaurant_id;
      this.getRestaurantDetails({
        vendor_id: this.$auth.getVendorId(),
        restaurant_id: restaurantId,
        is_langauge: this.$store.state.lang.locale,
        latitude: parseFloat(localStorage.getItem("latitude")),
        longitude: parseFloat(localStorage.getItem("longitude")),
      }).then((data) => {
        if (data.code == 200) {
          this.restMinimumOrder = data.Result.minimum_order_value;
          this.alertModal(this.restMinimumOrder);
        }
      });
    },

    alertModal(restMinimumOrder) {
      if (
        parseFloat(this.getTotalAmount().toFixed(2)) >=
        parseFloat(restMinimumOrder)
      ) {
        this.is_btn_spinner = false;
        this.$router.push({ name: "review-cart" });
      } else {
        this.AlertData = {
          displayMessage:
            this.$t("Minimum items total must be ") +
            this.$auth.setting.currency +
            restMinimumOrder,
          isSetClass: "cart-modal-cls",
        };
        this.isAlert = true;
        window.$(this.$refs.AlertModal).modal();
        this.is_btn_spinner = false;
      }
    },

    cleanCart() {
      this.$swal({
        title: this.$t("Are you sure you want to clear cart?"),
        type: "warning",
        customClass: {
          confirmButton: "cofirm-btn-new",
          cancelButton: "cancle-btn-new",
          title: "clear-cart-text mt-3 mb-1",
        },
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: this.$t("Yes"),
      }).then((result) => {
        // <--
        if (result.isConfirmed) {
          // <-- if confirmed
          this.$store.commit("product/clearCartItems");
        }
      });
    },
  },
};
</script>
